import React, { useRef } from 'react';
import useIntersectionObserver from '@react-hook/intersection-observer';
import { navigate } from 'gatsby';
import styles from './index.module.scss';
import { FEATURES_TEXT } from '../../constans/texts/texts';
import Button from '../UI/Button/Button';
import { SEARCH_LINK } from '../../constans/links';
import { VideoEmulatorLaptop, VideoEmulatorMobile } from '../VideoImulator/VideoEmulator';

const desktopVideoLink = 'https://s3.ap-east-1.amazonaws.com/bucket-koralgo-stage/files/user_1/koralgo_file_94470bb6-40cd-4fb6-8a22-93a896e83960.mp4';
const mobileVideoLink = 'https://s3.ap-east-1.amazonaws.com/bucket-koralgo-stage/files/user_1/koralgo_file_73bcc5b2-bbef-4ee2-852e-7cf50d49f1c2.mp4';

function Laptop() {
  const containerRef = useRef();
  const lockRef = useRef(false);
  const { isIntersecting } = useIntersectionObserver(containerRef);
  if (isIntersecting) {
    lockRef.current = true;
  }

  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <div className={styles.description}>
          <h1 className={styles.descriptionCentered}>Your trustworthy travel planning app</h1>
          <p className={styles.textDescription}>
            We’ve done all the research (so you don’t
            have to!) and use our magic wand to
            generate the best free personalized
            itineraries available on the web, period.
          </p>
          <p className={styles.textDescription}>
            Oh, and we also help you choose the best
            hotel rooms based on your budget &
            preferences.
          </p>
          <Button
            onClick={() => navigate(SEARCH_LINK)}
            classes={`${styles.submitButton} t-600 btn btn_primary-danger`}
          >
            Get started
          </Button>
        </div>
        <div className={styles.hideVideoBlock}>
          <VideoEmulatorLaptop isMainPage containerRef={containerRef} link={desktopVideoLink} />
        </div>
      </div>

      <VideoEmulatorMobile link={mobileVideoLink} />

      <div className={styles.listBlock}>
        <div>
          <h2 className={styles.descriptionCentered}>
            We can do so much to simplify your experience
          </h2>
        </div>
        <div className={styles.table}>
          {FEATURES_TEXT.map((item, index) => (
            <div className={styles.tableItemBlock}>
              <img src={item.icon} className={index === 1 ? styles.worldIcon : styles.image} alt="" />
              <h3>{item.label}</h3>
              <p>{item.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Laptop;
