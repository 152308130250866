import React from 'react';
import styles from '../Laptop/index.module.scss';
import LaptopImage from '../../assets/img/laptop.png';
import MobileImage from '../../assets/img/iphoneLanding.png';

const laptopPosterLink = 'https://s3.ap-east-1.amazonaws.com/bucket-koralgo-stage/files/user_1/koralgo_file_399b80a6-46a8-410f-8572-0959f0a3e523.webp';
const mobilePosterLink = 'https://s3.ap-east-1.amazonaws.com/bucket-koralgo-stage/files/user_1/koralgo_file_0cb0a8d1-800e-438d-b518-d46d67579d15.png';

export function VideoEmulatorLaptop({
  containerRef,
  imgWidth = 908,
  imgHeight = 460,
  videoWidth = 720,
  videoHeight = 640,
  link,
  isMainPage = false,
}) {
  return (
    <div className={styles.mediaContent}>
      <img
        className={styles.imageBlock}
        src={LaptopImage}
        alt=""
        role="presentation"
        width={imgWidth}
        height={imgHeight}
      />
      <div ref={containerRef} className={`${styles.videoBlock} ${!isMainPage && styles.videoStyles}`}>
        <video
          className="lazy"
          autoPlay
          muted
          loop
          playsInline
          width={videoWidth}
          height={videoHeight}
          poster={laptopPosterLink}
        >
          <source
            src={link}
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
}

export function VideoEmulatorMobile({ link }) {
  return (
    <div className={styles.tableItemBlock}>
      <img src={MobileImage} className={styles.phoneStyles} alt="" />
      <div className={`${styles.mobileVideoBlock}`}>
        <video
          className="lazy"
          autoPlay
          muted
          loop
          playsInline
          width={269}
          poster={mobilePosterLink}
        >
          <source
            src={link}
            type="video/mp4"
          />
        </video>
      </div>
    </div>
  );
}
